<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';
import { Tag } from '@leon-hub/tags';

import { ButtonKind, SButton, VButton } from '@components/buttons';
import { VHintBlock } from '@components/hint-block';
import { VLoader } from '@components/loader';

import { VForm } from 'web/src/components/Form';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { useDisableCookiesGuard } from 'web/src/modules/dialogs/composables/useDisableCookiesGuard';
import useLoginPage from 'web/src/modules/login/pages/LoginRoutePage/composables/useLoginPage';

import LoginTermsFlow from './components/LoginTermsFlow.vue';

defineProps<LoginRouteProps>();

interface LoginRouteProps {
  isFlowLogin?: boolean;
}

useDisableCookiesGuard();

const {
  loginForm,
  tabsProperties,
  onTabClick,
  isSubmitPending,
  isFormReady,
  schema,
  uiSchema,
  onChange,
  onInput,
  onSignIn,
  incorrectLoginMessage,
  incorrectHintBlockProperties,
  submitButtonProperties,
  onClickSingInButton,
  onRegistrationButtonClick,
  isCaptchaEnabled,
  loginType, // for form key, temp solution before LEONWEB-11751
  isShowTabsLogin,
} = useLoginPage();
</script>

<template>
  <div v-auto-id="'LoginRoutePage'"
    v-data-test="{ el: 'login-page' }"
    :class="$style['login']"
  >
    <template v-if="null">
      <div :class="$style['login__title']">
        {{ $t('THE_SITE_ENTRANCE') }}
      </div>
      <VSwitcher
        v-if="isShowTabsLogin"
        :class="$style['login__tabs']"
        :options="tabsProperties.items"
        :kind="SwitcherKind.PRIMARY"
        :active-id="loginType"
        @change="onTabClick"
      />
    </template>
    <VTabs
      v-else-if="isShowTabsLogin"
      :class="$style['login__tabs']"
      v-bind="tabsProperties"
      full-width
      with-gap
      @tab-click="onTabClick"
    />
    <div
      v-if="isFormReady"
      :class="$style['login__content']"
    >
      <VForm
        ref="loginForm"
        :class="$style['login__form']"
        :schema="schema"
        :ui-schema="uiSchema"
        :is-pending="isSubmitPending"
        autocomplete="off"
        @change="onChange"
        @submit="onSignIn"
        @input="onInput"
      />
      <VHintBlock
        v-if="incorrectLoginMessage"
        v-bind="incorrectHintBlockProperties"
        :class="$style['login__form-hint']"
      />
      <div :class="$style['login__buttons-block']">
        <template
          v-if="null"
        >
          <SButton
            v-data-test="{ el: 'modal-button' }"
            kind="primary"
            size="large"
            :label="$t('WEB2_DO_LOGIN')"
            :class="$style['login__button']"
            full-width
            @click="onClickSingInButton"
          />
          <SButton
            v-data-test="{ el: 'modal-button' }"
            :kind="isFlowLogin ? 'tertiary-primary' : 'secondary'"
            size="large"
            :class="$style['login__button']"
            :label="$t('JSPNAV_USTAT_REGISTER')"
            :tag="Tag.A"
            :href="$router.resolve({ name: RouteName.REGISTRATION }).href"
            @click.prevent="onRegistrationButtonClick"
          />
        </template>
        <template
          v-else
        >
          <VButton
            v-data-test="{ el: 'modal-button' }"
            v-bind="submitButtonProperties"
            :class="$style['login__button']"
            full-width
            @click="onClickSingInButton"
          />
          <VButton
            v-data-test="{ el: 'modal-button' }"
            :class="$style['login__button']"
            full-width
            :kind="isFlowLogin ? ButtonKind.BASE : ButtonKind.TRANSPARENT"
            :label="$t('JSPNAV_FOOT_REGISTER')"
            @click="onRegistrationButtonClick"
          />
        </template>
      </div>
      <LoginTermsFlow
        v-if="isFlowLogin"
        @registration="onRegistrationButtonClick"
      />
      <VReCaptchaBadge
        v-else-if="isCaptchaEnabled"
        :lang="$lang"
      />
    </div>
    <VLoader v-else />
  </div>
</template>

<style lang="scss" module>
.login {
  &__title {
    @include font-title2s;

    padding: 0 16px;
    margin: 16px 0;
    color: var(--ColorContent1);

    @include is-app-layout-desktop {
      padding: 0 32px;
    }
  }

  &__tabs {
    padding: $loginTabPaddingMobile;

    @include is-app-layout-desktop {
      padding: $loginTabPaddingDesktop;
    }
  }

  &__content {
    padding: 0 16px 16px;

    @include is-app-layout-desktop {
      padding: 0 32px 32px;
    }
  }

  .tabs {
    padding: 0 8px;

    @include is-app-layout-desktop {
      padding: 0 24px;
    }
  }

  &__buttons-block {
    margin-top: 20px;
  }

  &__form {
    margin-top: 16px;
  }

  &__button {
    margin-bottom: 20px;
  }
}
</style>
