import type { AbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import useCasAuthStore from 'web/src/modules/cas/store/useCasAuthStore';

export default class LoginModulePrefetch extends AbstractPrefetch {
  prefetch(): AbstractPrefetchResult {
    const { getSavedLogins } = useCasAuthStore();
    void getSavedLogins();
  }
}
