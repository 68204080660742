import type { CaptchaType } from '@leon-hub/api-sdk';

import type { CaptchaToken } from 'web/src/modules/captcha/store/types';

export enum LoginApiErrorCode {
  LOGIN_BY_EMAIL_FAILED = 'LOGIN_BY_EMAIL_FAILED',
  LOGIN_BY_ACCOUNT_FAILED = 'LOGIN_BY_ACCOUNT_FAILED',
  LOGIN_BY_PHONE_FAILED = 'LOGIN_BY_PHONE_FAILED',
  FAILURE_INVALID_TOKEN = 'FAILURE_INVALID_TOKEN',
  FAILURE_TOKEN_AUTH_DISABLED = 'FAILURE_TOKEN_AUTH_DISABLED',
}

export interface CordovaLoginComposableOptions {
  resolveCaptcha(captchaType: CaptchaType): Promise<CaptchaToken>;
}
