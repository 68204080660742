<script lang="ts" setup>
import { useRouter } from 'vue-router';

import type { VButtonProps } from '@components/buttons';
import {
  ButtonKind,
  VButton,
} from '@components/buttons';

const { back } = useRouter();

const buttonTextProperties: VButtonProps = {
  kind: ButtonKind.TRANSPARENT,
  fullWidth: true,
};
</script>

<template>
  <div v-auto-id="'LoginTermsFlow'" :class="$style['form__terms']">
    <p :class="$style['form__terms-guest']">
      <VButton
        v-bind="buttonTextProperties"
        :label="$t('WEB2_LOGIN_GUEST')"
        :class="$style['form__terms-guest-btn']"
        @click="back()"
      />
    </p>
  </div>
</template>

<style lang="scss" module>
.form {
  &__terms {
    padding: 0 16px;
  }
}
</style>
